<template lang="pug">
p Get it by #[span(class='font-bold') {{ estimatedDeliveryDate }}]
</template>

<script setup lang="ts">
const { date } = defineProps<{
  date: string
}>()

const estimatedDeliveryDate = computed(() => {
  return new Date(date).toLocaleDateString(undefined, {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  })
})
</script>
